import {
    Heading,
    Container,
    Stack,
    Text,
    Image
} from '@chakra-ui/react'

const FeaturesItem = ({ heading, text, image, reverse }) => {
    const isReverse = reverse ? 'row-reverse' : 'row'
    
    return (
        <Container
            maxW={'4xl'}
        >
            <Stack
                direction={{ base: 'column', md: isReverse }}
                justify={'space-between'}
                align={'center'}
            >
                <Stack
                    direction={'column'}
                    spacing={2} 
                    ml={{ base: 5 }}
                    alignItems={{ base: 'center', sm: 'center', md: 'start', lg: 'start' }}
                    textAlign={{ base: 'center', sm: 'center', md: 'start', lg: 'start' }}
                >
                    <Heading fontSize={'3xl'} fontWeight={700}>
                        {heading}
                    </Heading>
                    <Text fontSize={'md'} fontWeight={600}>
                        {text}
                    </Text>
                </Stack>

                <Container   
                align={'center'}             
                >
                    <Image
                        width={'75%'}
                        height={'75%'}
                        minW={{ base: '0rem', md: '10rem' }}
                        objectFit={'cover'}
                        src={image}
                        rounded={'md'}
                    />
                </Container>
            </Stack>
        </Container>
    )
}

export default FeaturesItem